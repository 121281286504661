import tippy from 'tippy.js';
import toastr from "toastr";

tippy('[data-tippy-content]', {
    zIndex: 5
});


document.addEventListener('DOMContentLoaded', function() {
    const header = document.getElementById('header');
    if (header) {
        initHeaderScroll(header);
        initMenuChildCheck();
        initMenuClick();
    }
});

function initHeaderScroll(header) {
    let lastScrollTop = 0;
    let ticking = false;

    window.addEventListener('scroll', function() {
        if (!ticking) {
            window.requestAnimationFrame(function() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                if (scrollTop > lastScrollTop) {
                    // Scrolling down
                    header.classList.add('sticky');
                    header.classList.remove('hidden');
                } else {
                    // Scrolling up
                    header.classList.add('hidden');
                }

                if (scrollTop === 0) {
                    // At the top of the page
                    header.classList.remove('sticky');
                    header.classList.remove('hidden');
                }

                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
                ticking = false;
            });

            ticking = true;
        }
    });
}

function initMenuChildCheck() {
    const parentItems = document.querySelectorAll('li.header__nav-parent');

    parentItems.forEach(item => {
        const hasChild = item.querySelector('.nav__child');

        if (hasChild) {
            item.classList.add('has-child');
            addSvgIcon(item);
        }
    });
}

function addSvgIcon(item) {
    const link = item.querySelector('a.header__nav-link');

    // Utwórz element SVG
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('fill', 'none');
    svg.setAttribute('viewBox', '0 0 24 24');
    svg.setAttribute('stroke-width', '1.5');
    svg.setAttribute('stroke', 'currentColor');

    // Utwórz element path wewnątrz SVG
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('stroke-linejoin', 'round');
    path.setAttribute('d', 'M19.5 8.3 12 15.7 4.5 8.3');

    // Dodaj element path do elementu SVG
    svg.appendChild(path);

    // Dodaj SVG jako dziecko do linku <a>
    link.appendChild(svg);
}

// Popover kontakt
document.addEventListener('DOMContentLoaded', function () {
    const toggleContactLink = document.getElementById('toggleContact');
    const contactBox = document.querySelector('.header__contact');

    if (!toggleContactLink || !contactBox || !header) {
        console.error('Nie znaleziono jednego z wymaganych elementów: #toggleContact, .header__contact lub #header.');
        return;
    }

    toggleContactLink.addEventListener('click', function (e) {
        e.preventDefault();
        contactBox.classList.toggle('visible');
    });

    document.addEventListener('click', function (e) {
        if (!contactBox.contains(e.target) && !toggleContactLink.contains(e.target)) {
            contactBox.classList.remove('visible');
        }
    });

    function checkHeaderVisibility() {
        if (header.classList.contains('hidden')) {
            contactBox.classList.remove('visible');
        }
    }

    // Sprawdź widoczność headera na początku
    checkHeaderVisibility();

    // Nasłuchuj zmiany w klasach #header
    const headerObserver = new MutationObserver(function (mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                checkHeaderVisibility();
            }
        }
    });

    // Konfiguracja obserwatora dla atrybutu class
    headerObserver.observe(header, { attributes: true });

    // Usuń obserwator mutacji po zamknięciu strony lub niepotrzebności
    window.addEventListener('unload', function () {
        headerObserver.disconnect();
    });
});

// placeholdery w wyszukiwarce
function initializeTypewriteEffect() {
    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

        var that = this;
        var delta = 200 - Math.random() * 100;

        if (this.isDeleting) {
            delta /= 2;
        }

        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }

        setTimeout(function() {
            that.tick();
        }, delta);
    };

    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i = 0; i < elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
    };
}

// Wywołanie funkcji initializeTypewriteEffect() po załadowaniu skryptu
initializeTypewriteEffect();

document.addEventListener('DOMContentLoaded', function() {
    const searchField = document.getElementById('searchField');
    const typewrite = document.querySelector('.typewrite');

    searchField.addEventListener('input', function() {
        if (searchField.value.trim() !== '') {
            typewrite.classList.add('hidden');
        } else {
            typewrite.classList.remove('hidden');
        }
    });

    // Initial check on page load
    if (searchField.value.trim() !== '') {
        typewrite.classList.add('hidden');
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const toggleSearch = document.getElementById('toggleSearch');
    const searchForm = document.getElementById('searchForm');

    toggleSearch.addEventListener('click', function(event) {
        event.preventDefault(); // Prevent default anchor behavior

        searchForm.classList.toggle('hidden'); // Toggle the 'hidden' class on searchForm

        // Scroll to the top of the page
        if (!searchForm.classList.contains('hidden')) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Scroll to the top with smooth behavior
            });
        }
    });
});

function initMenuClick() {
    const parentItems = document.querySelectorAll('.header__nav-parent.has-child');

    parentItems.forEach(item => {
        const link = item.querySelector('.header__nav-link');
        const childMenu = item.querySelector('.nav__child');

        link.addEventListener('click', function(event) {
            event.preventDefault();

            const isHidden = childMenu.classList.contains('hidden');

            resetNavItems();

            if (isHidden) {
                childMenu.classList.remove('hidden');
                link.classList.add('active');
                document.body.style.overflowY = 'hidden';
                document.body.classList.add('blurred');
            } else {
                document.body.style.overflowY = '';
                if (!document.querySelector('.header__nav').classList.contains('is-visible')) {
                    document.body.classList.remove('blurred');
                }
            }
        });
    });
}

function resetNavItems() {
    const parentItems = document.querySelectorAll('.header__nav-parent.has-child');

    parentItems.forEach(item => {
        const link = item.querySelector('.header__nav-link');
        const childMenu = item.querySelector('.nav__child');

        link.classList.remove('active');
        childMenu.classList.add('hidden');
    });

    document.body.style.overflowY = '';
    if (!document.querySelector('.header__nav').classList.contains('is-visible')) {
        document.body.classList.remove('blurred');
    }
}

function setNavStyles() {
    const header = document.querySelector('.header');
    const nav = document.querySelector('.header__nav');

    if (nav.classList.contains('is-visible')) {
        const headerHeight = header.offsetHeight;
        nav.style.top = `${headerHeight}px`; // Ustawienie top na wysokość headera
        nav.style.height = `calc(100vh - ${headerHeight}px)`; // Wysokość na 100vh - wysokość headera
    } else {
        nav.style.top = '';
        nav.style.height = '';
    }
}

document.querySelector('.header__meta-hamburger').addEventListener('click', function() {
    const nav = document.querySelector('.header__nav');
    const hamburger = document.querySelector('.header__meta-hamburger .hamburger');

    nav.classList.toggle('is-visible');
    hamburger.classList.toggle('is-active');

    const isVisible = nav.classList.contains('is-visible');

    if (isVisible) {
        document.body.style.overflowY = 'hidden';
        document.body.classList.add('blurred');
    } else {
        document.body.style.overflowY = '';
        if (!document.querySelector('.header__nav').classList.contains('is-visible')) {
            document.body.classList.remove('blurred');
        }
        resetNavItems();
    }

    setNavStyles();
});

window.addEventListener('resize', setNavStyles);

// Inicjalizacja
initMenuClick();
setNavStyles(); // Ustawienie stylów na początku

// Copyrights
document.addEventListener('copy', function(event) {
    // Pobranie zaznaczonego tekstu
    let selectedText = window.getSelection().toString();

    // Dodanie własnego tekstu
    let additionalText = "\n\n | Więcej znajdziesz na opony.strefakierowcy.pl";
    let newText = selectedText + additionalText;

    // Zapobieganie domyślnemu działaniu kopiowania
    event.preventDefault();

    // Dodanie nowego tekstu do schowka
    if (event.clipboardData) {
        event.clipboardData.setData('text/plain', newText);
    } else if (window.clipboardData) { // starsze wersje IE
        window.clipboardData.setData('Text', newText);
    }
});

// + / - zamiast selecta
document.addEventListener("DOMContentLoaded", () => {
    // Sprawdź, czy element .basket jest obecny na stronie
    if (document.querySelector('.basket')) {
        // Jeśli jest, zakończ działanie skryptu
        return;
    }

    const products = document.querySelectorAll('.product');

    products.forEach(product => {
        const decrementButton = product.querySelector('.content__quantity-button--decrement');
        const incrementButton = product.querySelector('.content__quantity-button--increment');
        const quantityInput = product.querySelector('.content__quantity-input');

        if (decrementButton && incrementButton && quantityInput) {
            const updateQuantity = (delta) => {
                let currentValue = parseInt(quantityInput.value.replace(' szt.', '').trim());
                let newValue = currentValue + delta;
                if (newValue < 1) newValue = 1;
                if (newValue > 99) newValue = 99;
                quantityInput.value = `${newValue} szt.`;
            };

            decrementButton.addEventListener('click', () => {
                updateQuantity(-1);
            });

            incrementButton.addEventListener('click', () => {
                updateQuantity(1);
            });

            quantityInput.addEventListener('input', () => {
                let newValue = parseInt(quantityInput.value.replace(' szt.', '').trim());
                if (isNaN(newValue) || newValue < 1) newValue = 1;
                if (newValue > 99) newValue = 99;
                quantityInput.value = `${newValue} szt.`;
            });
        }
    });
});

// Disable autocomplete
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('input').forEach(input => input.setAttribute('autocomplete', 'off'));
});

// Opcjonalnie dla dynamicznie dodawanych inputów:
const observer = new MutationObserver(() => {
    document.querySelectorAll('input:not([autocomplete])').forEach(input => input.setAttribute('autocomplete', 'off'));
});

observer.observe(document.body, { childList: true, subtree: true });


// Alerty i required
const tireWidth = document.getElementById("tireWidth");
const tireProfile = document.getElementById("tireProfile");
const rimDiameter = document.getElementById("rimDiameter");
const showResultsButton = document.querySelector("button.button.button-secondary");

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

function updateRequiredAttributes() {
    const tireWidthValue = tireWidth.value;
    const tireProfileValue = tireProfile.value;
    const rimDiameterValue = rimDiameter.value;

    if (tireWidthValue || tireProfileValue || rimDiameterValue) {
        tireWidth.setAttribute("required", "true");
        tireProfile.setAttribute("required", "true");
        rimDiameter.setAttribute("required", "true");
    } else {
        tireWidth.removeAttribute("required");
        tireProfile.removeAttribute("required");
        rimDiameter.removeAttribute("required");
    }
}

function validateOnSubmit(event) {
    const tireWidthValue = tireWidth.value;
    const tireProfileValue = tireProfile.value;
    const rimDiameterValue = rimDiameter.value;

    let hasError = false;

    if (!tireWidthValue && (tireProfileValue || rimDiameterValue)) {
        toastr.error("Wybierz szerokość opony.");
        hasError = true;
    }
    if (!tireProfileValue && (tireWidthValue || rimDiameterValue)) {
        toastr.error("Wybierz profil opony.");
        hasError = true;
    }
    if (!rimDiameterValue && (tireWidthValue || tireProfileValue)) {
        toastr.error("Wybierz średnicę opony.");
        hasError = true;
    }

    if (hasError) {
        event.preventDefault(); // Zatrzymanie wysyłania formularza
    }
}

[tireWidth, tireProfile, rimDiameter].forEach(select => {
    select.addEventListener("change", updateRequiredAttributes);
});

showResultsButton.addEventListener("click", validateOnSubmit);

updateRequiredAttributes();